<template>
    <div>
        <div v-if="customerQuestionProp">
            <vs-popup class="holamundo " :title="$t('questions.questionAboutPopupHead', {bookingOptionName: customerQuestion.booking_option.name})" :active.sync="isActive" @close="closePopup">

                <div class="grid grid-cols-1">

                    <div class="w-full my-3">
                        <vs-chip v-if="customerQuestion.status == 'visible_to_customers'" transparent color="success">
                            {{ $t('questions.visibleToCustomers') }}
                        </vs-chip >
                        <vs-chip v-else transparent color="warning">
                            {{ $t('questions.notVisibleToCustomers') }}
                        </vs-chip >
                    </div>

                    <div class="w-full">
                        <vs-textarea :label="$t('questions.form.question')" height="150px" v-model="customerQuestion.question" class="w-full disabled-textarea" :disabled="true"/>
                    </div>
                    <div class="w-full">
                        <vx-tooltip :text="$t('questions.form.answerTooltip')" >
                            <vs-textarea :label="$t('questions.form.answer')" height="150px" v-model="customerQuestion.answer" class="w-full" :class="{'disabled-textarea': !customerQuestion.allow_edit_answer}" :disabled="!customerQuestion.allow_edit_answer"/>
                        </vx-tooltip>
                    </div>
    
                    <div class="w-full mt-5 mb-10">
                        <div class="flex w-full justify-end">
                            <vs-button icon-pack="feather" icon="icon-check" @click.native="submitForm" class="mx-2">{{$t('save')}}</vs-button>
                        </div>
                    </div>
                </div>
                

            </vs-popup>
        </div>
    </div>
</template>
  
<script>
// import moment from 'moment'
export default {
    name: 'answer-question-form-popup',
    components: {},
    computed: {
        isActive: {
            get() {
                return this.isAnswerQuestionActive
            },
            set(newState) {
                return newState
            }
        },
        currentVenueId () {
            return this.$store.getters.currentVenueId
        },
    },
    props: {
        isAnswerQuestionActive:  {
            type: Boolean,
            defult: false
        },
        customerQuestionProp: {
            type: Object,
            defult: null
        },
    },
    created() {},
    data:()=>({
        customerQuestion: null,
    }),
    methods: {
        async submitForm() {
            this.$store.dispatch('loader/loaderOn')
            let data = {
                answer: this.customerQuestion.answer
            }

            const response = await this.$store.dispatch('customersQuestions/answerQuestion', {venueId: this.currentVenueId, customerQuestion: this.customerQuestion});

            if(response.status == 200) {
                this.closePopup();
                this.$vs.notify({
                    color:'success',
                    text: response.data.message
                })
            } else {
                let errors = response.data.errors;
                Object.keys(errors).forEach(key => {
                    this.$vs.notify({
                        color:'danger',
                        icon: 'warning',
                        text: errors[key][0]
                    })
                });
            }
            this.$store.dispatch('loader/loaderOff')
        },
        closePopup() {
            this.$emit('update:isAnswerQuestionActive', false);
        },
    },
    watch: {
        isActive(newVal) {
            if(newVal) {
                let customerQuestion = JSON.parse(JSON.stringify(this.customerQuestionProp))
                this.customerQuestion = customerQuestion;
                
            }
        },
    }
  }
  </script>

<style>
.disabled-textarea{
    background-color: rgba(202, 202, 202, 0.3);
}
</style>
  

  