<template>
  <div>
    <!-- this popup is temp, will be removed after a while of relesing the Q&As feature -->
    <vs-popup class="holamundo"  :title="$t('questions.onBoardingPopup.title')" :active.sync="onBoardingPopup">
      <div class="flex justify-center my-5">
        <span class="text-4xl text-center">{{ $t('questions.onBoardingPopup.contectTitle') }}</span>
      </div>
      <div class="flex justify-center mb-5">
        <span class="text-lg text-center">{{ $t('questions.onBoardingPopup.contect') }}</span>
      </div>
      <div class="flex justify-center">
        <img src="@/assets/images/questions-and-answers/onboarding-hero.svg" style="height: 15rem;"/>
      </div>
      <div class="flex justify-end my-5">
        <vs-button type="filled" @click.native="onBoardingPopup = false">{{ $t('questions.onBoardingPopup.ok') }}</vs-button>
      </div>
    </vs-popup>

    <answer-question-form-popup :isAnswerQuestionActive.sync="isAnswerQuestionActive" :customerQuestionProp="currentQuestion"/>

    <div class="hidden lg:block">

      <vs-table
        class="mb-5"
        :sst="true"
        ref="SettlementsTable"
        @change-page="handleChangePage"
        v-model="selected"
        pagination
        :total="parseInt(tableConfig.total)"
        :max-items="parseInt(tableConfig.perPage)"
        :data="customersQuestions"
        @selected="showData"
        stripe
      >
  
      <template  slot="thead">
          <template>
            <vs-th class="text-lg"> {{ $t('questions.question') }}</vs-th>
            <vs-th class="text-lg"> {{ $t('questions.askedAt') }}</vs-th>
            <vs-th class="text-lg"> {{ $t('questions.answer') }}</vs-th>
            <vs-th class="text-lg"> {{ $t('questions.bookingOption') }}</vs-th>
            <vs-th class="text-lg"> {{ $t('questions.status') }}</vs-th>
          </template>
      </template>
  
      <template slot-scope="{data}">
        <tbody>
          <template>
              <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                  <vs-td>
                    <p class="product-name font-medium truncate">{{tr.question.slice(0, 15) + '...'}} </p>
                  </vs-td>  
                  <vs-td>
                    <p class="product-name font-medium truncate">{{moment(tr.created_at).locale($i18n.locale).fromNow()}}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium truncate">{{ tr.answer ? tr.answer.slice(0, 15) + '...' : '-' }}</p>
                  </vs-td>
                  <vs-td>
                    <p class="product-name font-medium truncate">{{ tr.booking_option.name }}</p>
                  </vs-td>
                  <vs-td>
                    <vs-chip v-if="tr.answer" transparent color="success">
                      {{ $t('questions.answered', {answeredAt: moment(tr.answered_at).format('D-M-Y H:mm')}) }}
                    </vs-chip >
                    <vs-chip v-else transparent color="warning">
                      {{ $t('questions.notAnswered') }}
                    </vs-chip >
                    <vs-chip v-if="tr.status == 'visible_to_customers'" transparent color="success">
                      {{ $t('questions.visibleToCustomers') }}
                    </vs-chip >
                    <vs-chip v-else transparent color="warning">
                      {{ $t('questions.notVisibleToCustomers') }}
                    </vs-chip >
                  </vs-td>
              </vs-tr>
          </template>
        </tbody>
      </template>
      </vs-table>
    </div>
    <div class="block lg:hidden">
      <div class="grid grid-cols-1 gap-6">
            <div v-for="(customersQuestion, index) in customersQuestions" :key="index" class="p-4 border rounded-lg shadow-md ">
              <!-- QUESTION -->
              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                    <svg class="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M7.529 7.988a2.502 2.502 0 0 1 5 .191A2.441 2.441 0 0 1 10 10.582V12m-.01 3.008H10M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                  </div>
                  <div>
                    {{ $t('questions.question') }}:
                  </div>
                </div>
                <div>
                  {{ customersQuestion.question.slice(0, 15) + '...' }}
                </div>
              </div>
              <!--// QUESTION //-->

              <!-- ADDED AT -->
              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                    <svg class="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linejoin="round" stroke-width="2" d="M10 6v4l3.276 3.276M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                  </div>
                  <div>
                    {{ $t('questions.askedAt') }}:
                  </div>
                </div>
                <div>
                  {{ moment(customersQuestion.created_at).locale($i18n.locale).fromNow() }}
                </div>
              </div>
              <!--// ADDED AT //-->

              <!-- ANSWER -->
              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                    <svg class="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M8 9h2v5m-2 0h4M9.408 5.5h.01M19 10a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"/>
                    </svg>
                  </div>
                  <div>
                    {{ $t('questions.answer') }}:
                  </div>
                </div>
                <div>
                  {{ customersQuestion.answer ? customersQuestion.answer.slice(0, 15) + '...' : '-' }}
                </div>
              </div>
              <!--// ANSWER //-->

              <!-- BOOKING OPTION -->
              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                    <svg class="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 19 18">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.583 5.445h.01M8.86 16.71l-6.573-6.63a.993.993 0 0 1 0-1.4l7.329-7.394A.98.98 0 0 1 10.31 1l5.734.007A1.968 1.968 0 0 1 18 2.983v5.5a.994.994 0 0 1-.316.727l-7.439 7.5a.975.975 0 0 1-1.385.001Z"/>
                    </svg> 
                  </div>
                  <div>
                    {{ $t('questions.bookingOption') }}:
                  </div>
                </div>
                <div>
                  {{ customersQuestion.booking_option.name }}
                </div>
              </div>
              <!--// BOOKING OPTION //-->

              <!-- STATUS -->
              <div class="grid grid-cols-2">
                <div class="flex justify-start gap-2 mb-4">
                  <div>
                    <svg class="w-5 h-5 text-gray-800" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                      <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M18 5h1v12a2 2 0 0 1-2 2m0 0a2 2 0 0 1-2-2V2a1 1 0 0 0-1-1H2a1 1 0 0 0-1 1v15a2 2 0 0 0 2 2h14ZM10 4h2m-2 3h2m-8 3h8m-8 3h8m-8 3h8M4 4h3v3H4V4Z"/>
                    </svg>
                  </div>
                  <div>
                    {{ $t('questions.status') }}:
                  </div>
                </div>
                <div>
                  <vs-chip v-if="customersQuestion.answer" transparent color="success">
                    {{ $t('questions.answered', {answeredAt: customersQuestion.answered_at}) }}
                  </vs-chip >
                  <vs-chip v-else transparent color="warning">
                    {{ $t('questions.notAnswered') }}
                  </vs-chip >
                </div>
              </div>
              <!--// STATUS //-->

              <div class="flex items-center justify-center mt-6"> 
                <vs-button @click="showData(customersQuestion)" type="border" icon="info" class="w-full">
                  {{ $t('questions.details') }}
                </vs-button>
              </div>
          </div>
        </div>
        <vs-pagination class="mt-5" :total="Math.ceil(tableConfig.total / tableConfig.perPage)" :max="5" v-model="tableConfig.currentPage" @input="handleChangePage"></vs-pagination>
    </div>
 </div>
</template>

<script>
import moment from 'moment';
import AnswerQuestionFormPopup from '@/components/CustomersQuestions/AnswerQuestionFormPopup.vue';
export default {
  components: {
    AnswerQuestionFormPopup
  },
  data () {
    return {
      moment:moment,
      selected: [],
      tableConfig: {
          total: 0,
          perPage: 5,
          currentPage: 1,
      },
      currentQuestion: null,
      isAnswerQuestionActive: false,
      onBoardingPopup: true,
    }
  },
  computed: {
    customersQuestions () {
      return this.$store.state.customersQuestions.customersQuestions;
    },
    currentVenueId () {
        return this.$store.getters.currentVenueId
    },
  },

  created () {
    this.getCustomersQuestions(1)
  },

  methods: {
    async getCustomersQuestions(page = 1) {
      let query = {
        page: page,
        per_page: this.tableConfig.perPage
      }
      const response = await this.$store.dispatch('customersQuestions/getQuestions', {venueId : this.currentVenueId , params: query});

      if(response.status == 200) {
        this.$store.dispatch('loader/loaderOff')
        this.tableConfig.total = response.data.meta.total
      }
      
    },
    handleChangePage(page) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
      this.tableConfig.currentPage = page;
      this.getCustomersQuestions(page);
    },
    showData (data) {
      this.isAnswerQuestionActive = true;
      this.currentQuestion = data;
    },
  },
  watch: {
    
  },

}
</script>

<style>

</style>
